import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { ResultView, TestTypeResult, TestTypes } from '../../models';
import { addUtmToLink, scrollToBlock } from '../../utils';
import { device } from '../commonStyled';

import ResultCircles from './ResultCircle';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ResultBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.24);
    padding: 40px 16px;
    width: 100%;
    max-width: 890px;
`;

const Text = styled.div`
    font-size: 20px;
    text-align: center;
`;

const BlackButton = styled.a`
    position: relative;
    display: inline-block;
    padding: 16px 50px 16px;
    color: #fff;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    text-decoration: none;
    background: #000;
    box-shadow: 0px 3px 4.75px 0.25px rgba(0, 0, 0, 0.56);
    border-radius: 45px;
    transition: all 0.3s linear;
    margin: 28px 0;

    &:hover {
        background: ${COLORS.red};
        color: #fff;
        text-decoration: none;
    }

    @media ${device.mobileBig} {
        font-size: 20px;
        padding: 12px 20px 12px;
    }

    @media ${device.mobileMedium} {
        font-size: 20px;
        padding: 12px;
    }
`;

interface TestResultsProps {
    value: number | TestTypeResult[];
    resultLink: string;
    className?: string;
    visibleTypesCount?: number;
    testType: TestTypes;
    textBefore: string;
    buttonText: string;
    textAfter: string;
    resultView: ResultView;
}

const TestResults: React.FC<TestResultsProps> = ({
    className,
    value,
    resultLink,
    testType,
    visibleTypesCount,
    textBefore,
    buttonText,
    textAfter,
    resultView,
}) => {
    useEffect(() => {
        scrollToBlock('#results', { offset: -200 });
    }, []);

    return (
        <Container id="results" className={className}>
            <ResultCircles
                testType={testType}
                visibleTypesCount={visibleTypesCount}
                value={value}
                resultView={resultView}
            />
            <ResultBlock>
                <Text dangerouslySetInnerHTML={{ __html: textBefore }} />
                <BlackButton
                    href={addUtmToLink(resultLink)}
                    target="_blank"
                    rel="nofollow"
                >
                    {buttonText}
                </BlackButton>
                <Text dangerouslySetInnerHTML={{ __html: textAfter }} />
            </ResultBlock>
        </Container>
    );
};

export default TestResults;
