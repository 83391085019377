import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { ResultView, TestTypeResult, TestTypes } from '../../models';
import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { device, RichTextView } from '../commonStyled';
import { Box } from '../Box';

const Container = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;

    width: 100%;

    @media ${device.mobileBig} {
        flex-direction: column;
    }
`;

const Title = styled.div`
    font-size: 56px;
    margin-bottom: 48px;

    @media ${device.mobileMedium} {
        font-size: 32px;
        margin-bottom: 24px;
    }
`;

const Circle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 157px;
    height: 157px;
    margin-bottom: 64px;

    box-shadow: inset 0px 0px 5.64px 0.36px rgba(28, 39, 41, 0.3);
    border-radius: 50%;

    @media ${device.mobileMedium} {
        margin-bottom: 24px;
    }
`;

const ResultValue = styled.div`
    font-size: 56px;
    font-family: ${FONT_GOTHAM_MEDIUM};
`;

const TypeValue = styled.div`
    font-size: 22px;

    @media ${device.mobileBig} {
        font-size: 20px;
    }
`;

const CircleWrapper = styled.div<{ width: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: ${({ width }) => width}%;
    padding: 0 10px 34px;

    @media ${device.laptopSmall} {
        padding: 0 2px 18px;
    }

    @media ${device.mobileBig} {
        width: 100%;
    }
`;

const TypeNumber = styled.div`
    text-transform: uppercase;
    font-family: ${FONT_GOTHAM_MEDIUM};
    font-weight: bold;
    margin: 0 0 7px;
    font-size: 39px;
    line-height: 48px;

    @media ${device.laptopSmall} {
        font-size: 25px;
    }
`;

const TypeTitle = styled.div`
    margin: 0 0 8px;
    font-size: 30px;
    line-height: 36px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    font-weight: bold;
    color: ${COLORS.red};
    text-align: center;
    min-height: 72px;

    @media ${device.laptopSmall} {
        font-size: 20px;
    }

    @media ${device.mobileBig} {
        min-height: auto;
        margin: 0 0 12px;
    }
`;

const SumResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ImageStyled = styled.img`
    max-width: 400px;
    width: 100%;
    height: auto;
`;

const getVisibleFrequentTypes = (
    results: TestTypeResult[],
    visibleTypesCount: number
) => {
    const sortedResults = [...results].sort(
        (a, b) => b.frequency - a.frequency
    );

    const visibleTypes = sortedResults.splice(0, visibleTypesCount);

    return visibleTypes;
};

const getCircleWidth = (count: number) => {
    switch (count) {
        case 1:
            return 100;
        case 2:
            return 40;
        default:
            return 100 / count;
    }
};

const formatToPercent = (number) => Math.round(number * 100);

const renderContent = (content: string) =>
    content && (
        <Box mt={24} textAlign="center">
            <RichTextView
                fontSize={16}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </Box>
    );

const getCircles = ({
    testType,
    value,
    visibleTypesCount,
    resultView,
}: Pick<
    TestResultsProps,
    'testType' | 'value' | 'visibleTypesCount' | 'resultView'
>) => {
    if (resultView === ResultView.images) {
        const visibleTypes = getVisibleFrequentTypes(
            value as TestTypeResult[],
            visibleTypesCount
        );

        return visibleTypes.map(({ image, type, content }) => (
            <CircleWrapper key={type} width={getCircleWidth(visibleTypesCount)}>
                <ImageStyled src={image} />
                {renderContent(content)}
            </CircleWrapper>
        ));
    }

    if (testType === TestTypes.sum) {
        return (
            <SumResultContainer>
                <Title>Вы набрали</Title>
                <Circle>
                    <ResultValue>{value}</ResultValue>
                    <TypeValue>баллов</TypeValue>
                </Circle>
            </SumResultContainer>
        );
    }

    if (testType === TestTypes.type) {
        const visibleTypes = getVisibleFrequentTypes(
            value as TestTypeResult[],
            visibleTypesCount
        );

        return visibleTypes.map(({ title, type, content }) => (
            <CircleWrapper key={type} width={getCircleWidth(visibleTypesCount)}>
                <TypeTitle>{title}</TypeTitle>
                <Circle>
                    <ResultValue>{type}</ResultValue>
                    <TypeValue>ТИП</TypeValue>
                </Circle>
                {renderContent(content)}
            </CircleWrapper>
        ));
    }

    if (testType === TestTypes.percent) {
        const visibleTypes = getVisibleFrequentTypes(
            value as TestTypeResult[],
            visibleTypesCount
        );

        return visibleTypes.map(({ title, frequency, type, content }) => (
            <CircleWrapper key={type} width={getCircleWidth(visibleTypesCount)}>
                <TypeNumber>ТИП {type}</TypeNumber>
                <TypeTitle>{title}</TypeTitle>
                <Circle>
                    <ResultValue>{formatToPercent(frequency)}%</ResultValue>
                </Circle>
                {renderContent(content)}
            </CircleWrapper>
        ));
    }

    return null;
};

interface TestResultsProps {
    value: number | TestTypeResult[];
    className?: string;
    visibleTypesCount?: number;
    testType: TestTypes;
    resultView: ResultView;
}

const ResultCircles: React.FC<TestResultsProps> = ({
    className,
    value,
    visibleTypesCount,
    testType,
    resultView,
}) => {
    return (
        <Container className={className}>
            {getCircles({ testType, value, visibleTypesCount, resultView })}
        </Container>
    );
};

export default ResultCircles;
